<template>
  <div class="contact">
    <div class="contact__box">
      <div class="contact__header">
        <h1>Skontaktuj się z nami</h1>
        <p>
          Masz problem z logowaniem lub chcesz podzielić się z nami swoją
          opinią? Napisz do nas!
        </p>
      </div>

      <a-form
        class="contact__form"
        ref="refForm"
        name="contact"
        :model="formModel"
        :rules="formRules"
        @submit="send"
      >
        <a-form-item has-feedback name="email">
          <a-input
            placeholder="Adres e-mail"
            autocomplete="off"
            v-model:value="formModel.email"
          />
        </a-form-item>

        <a-form-item has-feedback name="name">
          <a-input
            placeholder="Twoje imię i nazwisko"
            autocomplete="off"
            v-model:value="formModel.name"
          />
        </a-form-item>

        <a-form-item has-feedback name="subject">
          <a-input
            placeholder="Temat wiadomości"
            autocomplete="off"
            v-model:value="formModel.subject"
          />
        </a-form-item>

        <a-form-item class="contact__textarea" has-feedback name="message">
          <a-textarea
            placeholder="Wpisz swoje pytanie..."
            autocomplete="off"
            v-model:value="formModel.message"
          />
        </a-form-item>

        <vue-recaptcha
          :sitekey="recaptchaSiteKey"
          :loadRecaptchaScript="true"
          @verify="recaptchaSuccess = true"
        >
        </vue-recaptcha>

        <div class="contact__actions" type="flex">
          <a-col :lg="12" :md="12" :sm="12" :xs="24">
            <a-button
              class="contact__button"
              type="primary"
              html-type="submit"
              :loading="loading"
              :disabled="!valid || !recaptchaSuccess"
            >
              Wyślij wiadomość
            </a-button>
            <router-link to="/">
              <a-button class="contact__button" type="text">
                Anuluj
              </a-button>
            </router-link>
          </a-col>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import VueRecaptcha from "vue-recaptcha";

const REQUIRED_ERROR_MESSAGE = "Pole jest wymagane";

export default {
  name: "Contact",
  inject: ["recaptchaSiteKey"],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      valid: false,
      loading: false,
      recaptchaSuccess: false,
      formModel: {
        email: "",
        name: "",
        subject: "",
        message: "",
      },
      formRules: {
        email: [
          {
            required: true,
            pattern: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
            message: "Podaj prawidłowy adres email",
          },
        ],
        name: [
          {
            required: true,
            message: REQUIRED_ERROR_MESSAGE,
          },
        ],
        subject: [
          {
            required: true,
            message: REQUIRED_ERROR_MESSAGE,
          },
        ],
        message: [
          {
            required: true,
            message: REQUIRED_ERROR_MESSAGE,
          },
        ],
      },
    };
  },
  watch: {
    formModel: {
      async handler() {
        if (!this.loading) {
          this.validatePromise = this.$refs.refForm
            .validate()
            .then(() => {
              this.valid = true;
            })
            .catch(() => {
              this.valid = false;
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    send() {
      this.loading = true;
      this.$http
        .post("patient-portal/api/contact/message", {
          name: this.formModel.name,
          email: this.formModel.email,
          message: `Tytuł: ${this.formModel.subject} Wiadomość: ${this.formModel.message}`,
        })
        .then(() => {
          this.$refs.refForm.resetFields();
          nextTick(() => {
            this.loading = false;
            this.$notification.open({
              message: "Wiadomość została wysłana pomyślnie",
              class: "success",
            });
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notification.open({
            message: "Wystąpił błąd podczas wysyłania wiadomości",
            class: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$contact-box-margin: 5vh;
$contact-box-width: $container-width/2;

.contact {
  max-width: $container-width;
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: inherit;
  padding: 10px;

  .contact__box {
    width: $contact-box-width;
    min-height: calc(
      100vh - #{$header-height} - #{$footer-height} - 2 *#{$contact-box-margin}
    );
    background-color: white;
    display: flex;
    flex-direction: column;

    @media (max-width: $contact-box-width) {
      width: 100%;
    }

    .contact__header {
      padding-bottom: 32px;
      background-color: $g2;
      p {
        color: $g1;
      }
    }
    .contact__form {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      padding: 24px;

      @media (max-width: $contact-box-width) {
        padding: 24px 10px;
      }

      .contact__textarea {
        flex: 1 0 auto;

        textarea {
          //temp solution
          min-height: 40vh;
          resize: none;
        }
      }

      .contact__actions {
        margin-top: 24px;
        .contact__button {
          height: 40px;
          margin: 0 12px 0 0;
        }
      }
    }
  }
}
</style>
